<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12">
          <h6 class="h3 text-white d-inline-block mb-0 text-right col-12">Olá, {{ userName }}</h6>
        </div>
      </div>

      <!-- Card stats -->
      <div class="row" v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'">
        <div class="col-xl-3 col-md-6">
          <stats-card title="$ Trafegado" type="gradient-red" :sub-title="dashTraffic.actualValueFmt"
            icon="ni ni-active-40">

            <template slot="footer">
              <span v-show="dashTraffic.percentValue > 0" class="text-success mr-2"><i class="fa fa-arrow-up"></i>
                {{ dashTraffic.percentValue }}%</span>
              <span v-show="dashTraffic.percentValue <= 0" class="text-danger mr-2"><i class="fa fa-arrow-down"></i>
                {{ dashTraffic.percentValue }}%</span>
              <span class="text-nowrap">mês anterior</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="ATRASADOS" type="gradient-orange" :sub-title="dashDebt.actualValue"
            icon="ni ni-chart-pie-35">

            <template slot="footer">
              <span v-show="dashDebt.percentValue > 0" class="text-danger mr-2"><i class="fa fa-arrow-up"></i>
                {{ dashDebt.percentValue }}%</span>
              <span v-show="dashDebt.percentValue <= 0" class="text-success mr-2"><i class="fa fa-arrow-down"></i>
                {{ dashDebt.percentValue }}%</span>
              <span class="text-nowrap">mês anterior</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Assinaturas" type="gradient-green" :sub-title="dashSubs.actualValue"
            icon="ni ni-money-coins">

            <template slot="footer">
              <span v-show="dashSubs.percentValue > 0" class="text-success mr-2"><i class="fa fa-arrow-up"></i>
                {{ dashSubs.percentValue }}%</span>
              <span v-show="dashSubs.percentValue <= 0" class="text-danger mr-2"><i class="fa fa-arrow-down"></i>
                {{ dashSubs.percentValue }}%</span>
              <span class="text-nowrap">mês anterior</span>
            </template>
          </stats-card>

        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card title="Crescimento" type="gradient-info" :sub-title="dashPerform.actualValue + '%'"
            icon="ni ni-chart-bar-32">
            <template slot="footer">
              <span class="text-nowrap">comparação/mês anterior</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6" v-if="getProfile() == 'MANAGER' || getProfile() == 'ADMIN'">
      <div class="row">
        <div class="col-xl-8">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-light text-uppercase ls-1 mb-1">Overview últimos 12 meses</h6>
                <h5 class="h3 text-white mb-0">Assinaturas</h5>
              </div>
            </div>
            <line-chart :height="350" ref="bigChart" :chart-data="graphSubsData.chartData"
              :extra-options="graphSubsData.extraOptions">
            </line-chart>
          </card>
        </div>

        <div class="col-xl-4">
          <card header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">Inadimplência</h6>
                <h5 class="h3 mb-0"></h5>
              </div>
            </div>
            <bar-chart :height="350" ref="barChart" :chart-data="graphDebtSixData.chartData">
            </bar-chart>
          </card>
        </div>
      </div>
      <!-- End charts-->
    </div>

  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/framework/Charts/config';
import LineChart from '@/components/framework/Charts/LineChart';
import BarChart from '@/components/framework/Charts/BarChart';

// Components
import StatsCard from '@/components/framework/Cards/StatsCard';

export default {
  components: {
    LineChart,
    BarChart,
    StatsCard,
  },
  data() {
    return {
      userName: localStorage.getItem("name"),
      dashTraffic: {
        actualValue: '0',
        percentValue: 0,
        actualValueFmt: '0'
      },
      dashDebt: {
        actualValue: '0',
        percentValue: 0
      },
      dashSubs: {
        actualValue: '0',
        percentValue: 0
      },
      dashPerform: {
        actualValue: '0'
      },
      graphSubsData: {
        activeIndex: 0,
        chartData: {
          datasets: [
            {
              label: 'Assinaturas',
              data: []
            }
          ],
          labels: [],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      graphDebtSixData: {
        chartData: {
          labels: [],
          datasets: [{
            label: 'Faturas',
            data: []
          }]
        }
      }
    };
  },
  methods: {
    getProfile() {
      return localStorage.getItem("profile")
    },
    getDashTraffic() {
      this.$clubApi.get('/dashboard/traficAmount')
        .then((response) => {
          this.dashTraffic = response.data.object
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    },
    getDashDebt() {
      this.$clubApi.get('/dashboard/debitQuantity')
        .then((response) => {
          this.dashDebt = response.data.object
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    },
    getDashSubs() {
      this.$clubApi.get('/dashboard/totalSubscriptions')
        .then((response) => {
          this.dashSubs = response.data.object
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    },
    getDashPerform() {
      this.$clubApi.get('/dashboard/performance')
        .then((response) => {
          this.dashPerform = response.data.object
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    },
    getGraphSubsMonths() {
      this.$clubApi.get('/dashboard/graph/subs')
        .then((response) => {
          this.graphSubsData.chartData = {
            datasets: [
              {
                label: 'Assinaturas',
                data: response.data.object.subs
              }
            ],
            labels: response.data.object.months
          }
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    },
    getGraphDebtLastSixMonths() {
      this.$clubApi.get('/dashboard/graph/debitHistory')
        .then((response) => {
          this.graphDebtSixData.chartData = {
            datasets: [
              {
                label: 'Faturas',
                data: response.data.object.values
              }
            ],
            labels: response.data.object.months
          }
        }).catch((error) => {
          this.$notify({ type: 'warning', message: error.response.data.msg })
        }).finally(() => {
          NProgress.done()
        })
    }
  },
  mounted() {
    if (this.getProfile() == 'MANAGER' || this.getProfile() == 'ADMIN') {
      this.getDashTraffic()
      this.getDashDebt()
      this.getDashSubs()
      this.getDashPerform()
      this.getGraphSubsMonths()
      this.getGraphDebtLastSixMonths()
    }
  }
};
</script>
<style></style>
